import React from 'react';
import { createSvgIcon } from '@mui/material';

const MoreIcon = createSvgIcon(
  <path
    d="M-4247.45-4181.75a1.249,1.249,0,0,1,1.25-1.25,1.249,1.249,0,0,1,1.25,1.25,1.249,1.249,0,0,1-1.25,1.25A1.249,1.249,0,0,1-4247.45-4181.75Zm-6.276,0a1.25,1.25,0,0,1,1.251-1.25,1.25,1.25,0,0,1,1.25,1.25,1.25,1.25,0,0,1-1.25,1.25A1.25,1.25,0,0,1-4253.726-4181.75Zm-6.274,0a1.25,1.25,0,0,1,1.25-1.25,1.25,1.25,0,0,1,1.251,1.25,1.25,1.25,0,0,1-1.251,1.25A1.25,1.25,0,0,1-4260-4181.75Z"
    transform="translate(4264.5 4193.75)"
    fill="#29aaff"
  />,
  'More',
);

export default MoreIcon;
