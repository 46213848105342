import React from 'react';
import { createSvgIcon } from '@mui/material';

const QueueActive = createSvgIcon(
  <g id="Очередь" transform="translate(0 0)">
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_189)">
      <rect id="Rectangle_189-2" data-name="Rectangle 189" width="24" height="24" rx="8" transform="translate(9 6)" fill="red" />
    </g>
    <path id="next" d="M1951,2415l9.335,5.5L1951,2426Zm1.255,8.805,5.609-3.3-5.609-3.307Zm9.1,2.009V2415h1.254v10.813Z" transform="translate(-1944.806 -2408.5)" fill="#fff" />
  </g>,
  'QueueActive',
);

export default QueueActive;
