import React from 'react';
import { createSvgIcon } from '@mui/material';

const DurationIcon = createSvgIcon(
  <path
    d="M1.167,8.93,3.5,6.6,5.833,8.93v2.411H1.167Zm0-5.25V1.167H5.833V3.68L3.5,6.014ZM0,0V3.972H.006L0,3.977,2.333,6.3,0,8.639l.006.006H0v3.863H7V8.644H6.994L7,8.639,4.667,6.3,7,3.977l-.006-.005H7V0Z"
    transform="translate(8.5 5.746)"
  />,
  'Duration',
);

export default DurationIcon;
