import React from 'react';
import { createSvgIcon } from '@mui/material';

const PercentIcon = createSvgIcon(
  <path
    d="M-6339.245-4038.629a2.632,2.632,0,0,1,2.629-2.629,2.633,2.633,0,0,1,2.63,2.629,2.633,2.633,0,0,1-2.63,2.629A2.632,2.632,0,0,1-6339.245-4038.629Zm1.315,0a1.316,1.316,0,0,0,1.314,1.315,1.315,1.315,0,0,0,1.314-1.315,1.315,1.315,0,0,0-1.314-1.315A1.316,1.316,0,0,0-6337.93-4038.629Zm-6.75,2.629,7.715-10h1.656l-7.713,10Zm-1.321-7.371a2.631,2.631,0,0,1,2.628-2.629,2.632,2.632,0,0,1,2.629,2.629,2.632,2.632,0,0,1-2.629,2.63A2.631,2.631,0,0,1-6346-4043.372Zm1.314,0a1.317,1.317,0,0,0,1.314,1.316,1.316,1.316,0,0,0,1.315-1.316,1.317,1.317,0,0,0-1.315-1.315A1.317,1.317,0,0,0-6344.686-4043.372Z"
    transform="translate(6352 4053)"
  />,
  'Percent',
);

export default PercentIcon;
