import React from 'react';
import { createSvgIcon } from '@mui/material';

const BlogIcon = createSvgIcon(
  <path
    d="M10.681,16a1.841,1.841,0,0,1-1.84-1.841V10.681a1.84,1.84,0,0,1,1.84-1.84h3.479A1.841,1.841,0,0,1,16,10.681v3.479A1.841,1.841,0,0,1,14.159,16Zm-.333-5.319v3.479a.333.333,0,0,0,.333.333h3.479a.333.333,0,0,0,.333-.333V10.681a.333.333,0,0,0-.333-.333H10.681A.333.333,0,0,0,10.348,10.681ZM1.84,16A1.841,1.841,0,0,1,0,14.159V10.681a1.841,1.841,0,0,1,1.84-1.84H5.319a1.841,1.841,0,0,1,1.84,1.84v3.479A1.841,1.841,0,0,1,5.319,16Zm-.333-5.319v3.479a.334.334,0,0,0,.333.333H5.319a.334.334,0,0,0,.333-.333V10.681a.334.334,0,0,0-.333-.333H1.84A.334.334,0,0,0,1.507,10.681Zm9.174-3.521a1.841,1.841,0,0,1-1.84-1.841V1.84A1.841,1.841,0,0,1,10.681,0h3.479A1.841,1.841,0,0,1,16,1.84V5.319a1.841,1.841,0,0,1-1.841,1.841ZM10.348,1.84V5.319a.333.333,0,0,0,.333.333h3.479a.333.333,0,0,0,.333-.333V1.84a.334.334,0,0,0-.333-.333H10.681A.334.334,0,0,0,10.348,1.84ZM1.84,7.159A1.841,1.841,0,0,1,0,5.319V1.84A1.841,1.841,0,0,1,1.84,0H5.319a1.841,1.841,0,0,1,1.84,1.84V5.319a1.841,1.841,0,0,1-1.84,1.841ZM1.507,1.84V5.319a.334.334,0,0,0,.333.333H5.319a.334.334,0,0,0,.333-.333V1.84a.334.334,0,0,0-.333-.333H1.84A.334.334,0,0,0,1.507,1.84Z"
    transform="translate(4 4)"
  />,
  'Blog',
);

export default BlogIcon;
