import React from 'react';
import { createSvgIcon } from '@mui/material';

const StarIcon = createSvgIcon(
  <path
    d="M530.709,841.647l1.666,3.014.332.6.674.13,3.381.654-2.352,2.516-.468.5.084.681.423,3.418L531.33,851.7l-.621-.291-.622.291-3.12,1.459.423-3.418.085-.681-.469-.5-2.351-2.516,3.381-.654.674-.13.332-.6,1.666-3.014m0-3.033-2.951,5.337-5.987,1.157,4.164,4.455-.75,6.052,5.524-2.583,5.523,2.583-.75-6.052,4.163-4.455-5.987-1.157-2.95-5.337Z"
    transform="translate(-518.708 -835.114)"
  />,
  'Star',
);

export default StarIcon;
