import React from 'react';
import { createSvgIcon } from '@mui/material';

const CardIcon = createSvgIcon(
  <path
    id="Union_21"
    data-name="Union 21"
    d="M-1983.43-6444.721a1.585,1.585,0,0,1-1.57-1.586v-6.572h21.99v6.572a1.591,1.591,0,0,1-1.573,1.586Zm11.258-3.935a2.5,2.5,0,0,0,2.494,2.5,2.485,2.485,0,0,0,1.4-.431,2.5,2.5,0,0,0,3.471-.671,2.506,2.506,0,0,0-.671-3.47,2.493,2.493,0,0,0-2.8,0,2.483,2.483,0,0,0-1.4-.425A2.492,2.492,0,0,0-1972.171-6448.656Zm3.894.443a1.381,1.381,0,0,1-1.3.941,1.386,1.386,0,0,1-1.383-1.382,1.384,1.384,0,0,1,1.383-1.382,1.382,1.382,0,0,1,1.3.939,1.382,1.382,0,0,1,1.3-.939,1.383,1.383,0,0,1,1.381,1.382,1.384,1.384,0,0,1-1.381,1.382A1.381,1.381,0,0,1-1968.277-6448.213Zm-16.722-6.05v-1.8h21.991v1.8Zm21.991-3.18H-1985v-1.016a1.553,1.553,0,0,1,1.57-1.541h18.854a1.556,1.556,0,0,1,1.576,1.541v1.016Z"
    transform="translate(1985 6460)"
  />,
  'Card',
);

export default CardIcon;
