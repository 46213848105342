import React from 'react';
import { createSvgIcon } from '@mui/material';

const AlarmIcon = createSvgIcon(
  <path
    d="M0,17.22V0H15.151V.008h.008v17.2h-.008v.008Zm1.406-1.406H13.753V1.406H1.406Zm5.407-2.74a.875.875,0,0,1,.009-1.18.866.866,0,0,1,.631-.229.862.862,0,0,1,.638.235.814.814,0,0,1,.233.595.8.8,0,0,1-.227.579.865.865,0,0,1-.644.232A.85.85,0,0,1,6.813,13.073ZM6.7,10.535A3.537,3.537,0,0,1,6.9,9.2a3.5,3.5,0,0,1,.823-1.1,6.357,6.357,0,0,0,.78-.85,1.486,1.486,0,0,0,.252-.832,1.267,1.267,0,0,0-.294-.9A1.126,1.126,0,0,0,7.6,5.2a1.245,1.245,0,0,0-.874.3,1.069,1.069,0,0,0-.331.828H4.866a2.261,2.261,0,0,1,.76-1.769A2.9,2.9,0,0,1,7.6,3.915a2.837,2.837,0,0,1,1.979.644,2.309,2.309,0,0,1,.71,1.8,2.95,2.95,0,0,1-.96,2.029l-.776.764a2.082,2.082,0,0,0-.43,1.384Z"
    transform="translate(4.42 3.39)"
  />,
  'Alarm',
);

export default AlarmIcon;
