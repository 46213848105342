const BASE_FONT_FAMILY = 'Roboto, sans-serif';
const LEFT_BAR_WIDTH = '240px';
const TOP_BAR_HEIGHT = '62px';
// base colors
const PRIMARY = '#29AAFF';
const SECONDARY = '#A85AFF';
const ERROR = '#FF2949';
const WARNING = '#FFC529';
const SUCCESS = '#2ECC71';
const INFO = '#5F6477';
const PRIMARY_LIGHT = '#1BD3EB';
const PRIMARY_DARK = '#45B5FF';
const WHITE = '#FFFFFF';
const SECONDARY_DARK = '#B36FFF';
const WARNING_DARK = '#FFCB40';
const SUCCESS_DARK = '#3BDB7F';
const MANATEE = '#898C99';
const BLACK_RUSSIAN = '#151516';
const BLUE_ZODIAC = '#484C5B';
const STATISTICS_CHART_RED = '#FF2949';
const STATISTICS_CHART_GREEN = '#2ECC71';
const STATISTICS_CHART_BLUE = '#29AAFF';
const STATISTICS_CHART_ORANGE = '#FFC529';
const STATISTICS_CHART_PURPLE = '#A85AFF';

export {
  LEFT_BAR_WIDTH,
  TOP_BAR_HEIGHT,
  BASE_FONT_FAMILY,
  PRIMARY,
  SECONDARY,
  ERROR,
  WARNING,
  SUCCESS,
  INFO,
  PRIMARY_LIGHT,
  PRIMARY_DARK,
  WHITE,
  SECONDARY_DARK,
  WARNING_DARK,
  SUCCESS_DARK,
  MANATEE,
  BLACK_RUSSIAN,
  BLUE_ZODIAC,
  STATISTICS_CHART_RED,
  STATISTICS_CHART_GREEN,
  STATISTICS_CHART_BLUE,
  STATISTICS_CHART_ORANGE,
  STATISTICS_CHART_PURPLE,
};
