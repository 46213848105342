import React from 'react';
import { createSvgIcon } from '@mui/material';

const LocationEIcon = createSvgIcon(
  <g>
    <path
      d="M12 4.2351C10.3963 4.22125 8.85276 4.84494 7.70883 5.96898C6.5649 7.09303 5.91428 8.62536 5.90002 10.2291C5.90002 14.1381 12 19.7631 12 19.7631C12 19.7631 18.1 14.1381 18.1 10.2291C18.0931 9.43494 17.9298 8.65 17.6195 7.919C17.3092 7.18799 16.8579 6.52528 16.2915 5.96868C15.725 5.41208 15.0545 4.97249 14.3182 4.67504C13.5818 4.37759 12.7941 4.22811 12 4.2351V4.2351Z"
      fill="#29AAFF"
    />
    <path
      d="M14.3906 12.3101V13H9.54785V12.3101H14.3906ZM9.68408 12.3101V14.5732H8.84473L8.84033 12.3101H9.68408ZM15.1289 12.3101L15.1201 14.5688H14.2808V12.3101H15.1289ZM13.5601 6.60156V7.2959H10.7563V6.60156H13.5601ZM14.2896 6.60156V13H13.4458V6.60156H14.2896ZM10.4663 6.60156H11.3145L11.1826 9.17676C11.165 9.56348 11.1284 9.9209 11.0728 10.249C11.0171 10.5742 10.9482 10.8716 10.8662 11.1411C10.7842 11.4106 10.6904 11.6523 10.585 11.8662C10.4795 12.0771 10.3667 12.2603 10.2466 12.4155C10.1265 12.5679 10.0005 12.6938 9.86865 12.7935C9.73975 12.8901 9.60938 12.959 9.47754 13H9.09082V12.3101H9.36328C9.43945 12.228 9.52588 12.1094 9.62256 11.9541C9.72217 11.7988 9.82031 11.5981 9.91699 11.3521C10.0166 11.103 10.103 10.7998 10.1763 10.4424C10.2524 10.085 10.3037 9.66309 10.3301 9.17676L10.4663 6.60156Z"
      fill="white"
    />
  </g>,
  'LocationE',
);

export default LocationEIcon;
