import localStorageFallback from 'local-storage-fallback';
import type { UserTokens } from '../common/types';

const storagePrefix = 'phedon_';

const storage = {
  getToken: () => JSON.parse(localStorageFallback.getItem(`${storagePrefix}token`) as string),
  getRefreshToken: () => JSON.parse(localStorageFallback.getItem(`${storagePrefix}refresh_token`) as string),
  setTokens: ({ accessToken, refreshToken }: UserTokens) => {
    localStorageFallback.setItem(`${storagePrefix}token`, JSON.stringify(accessToken));
    localStorageFallback.setItem(`${storagePrefix}refresh_token`, JSON.stringify(refreshToken));
  },
  clearStorage: () => {
    localStorageFallback.removeItem(`${storagePrefix}token`);
    localStorageFallback.removeItem(`${storagePrefix}refresh_token`);
  },
};

export default storage;
