import React from 'react';
import { createSvgIcon } from '@mui/material';

const BankIcon = createSvgIcon(
  <path
    id="Union_29"
    data-name="Union 29"
    d="M-8945-13001v-2.067h16.809v2.067Zm13.351-3.3v-6.536h1.979v6.536Zm-3.958,0v-6.536h1.979v6.536Zm-3.958,0v-6.536h1.981v6.536Zm-3.957,0v-6.536h1.979v6.536Zm-1.478-7.761v-2.068h16.809v2.068Zm.394-3.463,8.009-3.48,8.007,3.48Z"
    transform="translate(8944.997 13019)"
  />,
  'Card',
);

export default BankIcon;
