import React from 'react';
import { createSvgIcon } from '@mui/material';

const DotsIcon = createSvgIcon(
  <path
    d="M12.55,1.25A1.25,1.25,0,1,1,13.8,2.5,1.249,1.249,0,0,1,12.55,1.25Zm-6.276,0A1.25,1.25,0,1,1,7.525,2.5,1.25,1.25,0,0,1,6.274,1.25ZM0,1.25A1.25,1.25,0,1,1,1.25,2.5,1.25,1.25,0,0,1,0,1.25Z"
    transform="translate(0.5 11.2)"
  />,
  'Dots',
);

export default DotsIcon;
