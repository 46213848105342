import React from 'react';
import { createSvgIcon } from '@mui/material';

const CalendarIcon = createSvgIcon(
  <path
    d="M-4001-3979.759h-7v-11.391h3.028V-3992h1.076v.849h5.791V-3992h1.077v.849H-3994v11.391Zm-5.923-1.077h11.846v-9.238h-1.95v.844h-1.077v-.844h-5.791v.844h-1.076v-.844h-1.951Zm8.6-2.3a.837.837,0,0,1,.837-.837.837.837,0,0,1,.837.837.837.837,0,0,1-.837.837A.837.837,0,0,1-3998.321-3983.138Zm-3.517,0a.837.837,0,0,1,.837-.837.837.837,0,0,1,.837.837.836.836,0,0,1-.837.837A.837.837,0,0,1-4001.837-3983.138Zm-3.517,0a.837.837,0,0,1,.837-.837.837.837,0,0,1,.838.837.837.837,0,0,1-.838.837A.837.837,0,0,1-4005.354-3983.138Zm7.033-3.485a.837.837,0,0,1,.837-.837.837.837,0,0,1,.837.837.837.837,0,0,1-.837.837A.837.837,0,0,1-3998.321-3986.624Zm-3.517,0a.837.837,0,0,1,.837-.837.836.836,0,0,1,.837.837.836.836,0,0,1-.837.837A.837.837,0,0,1-4001.837-3986.624Zm-3.517,0a.837.837,0,0,1,.837-.837.837.837,0,0,1,.838.837.837.837,0,0,1-.838.837A.837.837,0,0,1-4005.354-3986.624Z"
    transform="translate(4013.001 3997.699)"
  />,
  'Calendar',
);

export default CalendarIcon;
