import React from 'react';
import { createSvgIcon } from '@mui/material';

const HeadphoneIcon = createSvgIcon(
  <path
    d="M-1976.98-6443.419a.279.279,0,0,1-.279-.279v-1.111a.279.279,0,0,1,.279-.279h1.946a.278.278,0,0,1,.277.279v1.111a.278.278,0,0,1-.277.279Zm-2.938-.092a3.451,3.451,0,0,1-3.366-2.667h1.5a2.028,2.028,0,0,0,1.863,1.229h1.9v1.438Zm7.583-3.434v-5.558h2.5a.834.834,0,0,1,.833.833v3.891a.834.834,0,0,1-.833.833Zm-11.83,0a.834.834,0,0,1-.835-.833v-3.891a.836.836,0,0,1,.835-.833h2.5v5.558Zm12.665-6.324a5.512,5.512,0,0,0-5.5-5.292,5.514,5.514,0,0,0-5.5,5.292h-1.438A6.937,6.937,0,0,1-1977-6460a6.931,6.931,0,0,1,6.934,6.73Z"
    transform="translate(1989 6463.7)"
  />,
  'Headphone',
);

export default HeadphoneIcon;
