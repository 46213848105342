import React from 'react';
import { createSvgIcon } from '@mui/material';

const ReverseAddressIcon = createSvgIcon(
  <path
    id="Union_7"
    data-name="Union 7"
    d="M0,12.735,4.36,9.5v2.353H13v1.764H4.36V15.97ZM11.641,4.116H3V2.352h8.641V0L16,3.234l-4.36,3.234Z"
    transform="translate(4 20) rotate(-90)"
  />,
  'Repeat',
);

export default ReverseAddressIcon;
