import React from 'react';
import { createSvgIcon } from '@mui/material';

const InstagramIcon = createSvgIcon(
  <path
    d="M41.2-236.272a4.691,4.691,0,0,1-4.684-4.679v-6.636a4.691,4.691,0,0,1,4.684-4.685h6.64a4.685,4.685,0,0,1,4.676,4.678v6.636a4.687,4.687,0,0,1-4.676,4.686Zm0-14.6a3.3,3.3,0,0,0-3.291,3.288v6.636a3.3,3.3,0,0,0,3.3,3.289h6.64a3.3,3.3,0,0,0,3.287-3.289v-6.636a3.3,3.3,0,0,0-3.287-3.288H41.2Zm3.326,10.953a4.35,4.35,0,0,1-4.343-4.356,4.347,4.347,0,0,1,1.267-3.058,4.314,4.314,0,0,1,3.069-1.278,4.348,4.348,0,0,1,4.294,4.4,4.349,4.349,0,0,1-4.278,4.29Zm0-7.3a2.957,2.957,0,0,0-2.954,2.962,2.959,2.959,0,0,0,.86,2.078,2.933,2.933,0,0,0,2.087.869,2.956,2.956,0,0,0,2.913-3,2.956,2.956,0,0,0-2.9-2.91l-.007-.077v.077Zm4.328-.681a.7.7,0,0,1-.7-.7.7.7,0,0,1,.2-.492.691.691,0,0,1,.49-.2.7.7,0,0,1,.642.746.7.7,0,0,1-.644.641Z"
    transform="translate(-36.517 256.272)"
  />,
  'Instagram',
);

export default InstagramIcon;
