import React from 'react';
import { createSvgIcon } from '@mui/material';

const AssignedVendorIcon = createSvgIcon(
  <path
    d="M-7878.566-187.816l-2.1,2.013,0-9.418,6.932,6.374-2.906.184,1.236,2.817-1.928.845Zm-8.437-6.733a6.45,6.45,0,0,1,6.451-6.451,6.448,6.448,0,0,1,6.446,6.451,6.416,6.416,0,0,1-.715,2.955l-.967-.887a5.142,5.142,0,0,0,.433-2.069,5.2,5.2,0,0,0-5.2-5.2,5.2,5.2,0,0,0-5.2,5.2,5.2,5.2,0,0,0,3.609,4.944l0,1.3A6.45,6.45,0,0,1-7887-194.549Zm2.582,0a3.87,3.87,0,0,1,3.87-3.87,3.866,3.866,0,0,1,3.866,3.87,3.815,3.815,0,0,1-.16,1.091l-1.1-1.006a.457.457,0,0,0,.005-.086,2.619,2.619,0,0,0-2.615-2.62,2.625,2.625,0,0,0-2.62,2.62,2.6,2.6,0,0,0,1.029,2.075v1.449A3.859,3.859,0,0,1-7884.421-194.549Z"
    transform="translate(7892.367 205)"
    fill="#29aaff"
  />,
  'AssignedVendorIcon',
);

export default AssignedVendorIcon;
