import React from 'react';
import { createSvgIcon } from '@mui/material';

const LocationAIcon = createSvgIcon(
  <g>
    <path
      d="M12 4.2351C10.3963 4.22125 8.85276 4.84494 7.70883 5.96898C6.5649 7.09303 5.91428 8.62536 5.90002 10.2291C5.90002 14.1381 12 19.7631 12 19.7631C12 19.7631 18.1 14.1381 18.1 10.2291C18.0931 9.43494 17.9298 8.65 17.6195 7.919C17.3092 7.18799 16.8579 6.52528 16.2915 5.96868C15.725 5.41208 15.0545 4.97249 14.3182 4.67504C13.5818 4.37759 12.7941 4.22811 12 4.2351V4.2351Z"
      fill="#29AAFF"
    />
    <path
      d="M12.1758 7.16846L10.0576 13H9.19189L11.6309 6.60156H12.189L12.1758 7.16846ZM13.9512 13L11.8286 7.16846L11.8154 6.60156H12.3735L14.8213 13H13.9512ZM13.8413 10.6313V11.3257H10.2466V10.6313H13.8413Z"
      fill="white"
    />
  </g>,
  'LocationA',
);

export default LocationAIcon;
