import React from 'react';
import { createSvgIcon } from '@mui/material';

const UserPriorityIcon = createSvgIcon(
  <g id="priority">
    <g>
      <rect id="Rectangle_189-2" width="24" height="24" rx="8" fill="none" />
    </g>
    <g id="Prior_notification">
      <rect id="Rectangle_34" width="24" height="24" fill="none" />
      <g id="Group_247" transform="translate(4 34)" fill="red">
        <path id="Fill-1" d="M1151.862,1147.155a3.029,3.029,0,1,1-3.029,3.028,3.031,3.031,0,0,1,3.029-3.028m0,8.674a6.944,6.944,0,0,1,4.656,1.608h0l-.711.711a6.59,6.59,0,0,0-7.89,0h0l-.712-.711A6.951,6.951,0,0,1,1151.862,1155.829Zm0-9.681a4.036,4.036,0,1,1-4.037,4.036A4.035,4.035,0,0,1,1151.862,1146.148Z" transform="translate(-1145.205 -1174.148)" fill="#fff" fillRule="evenodd" />
        <path id="Path_422" d="M1169.458,1157.571l-3.91,3.915-2.519-2.525.718-.717,1.8,1.8,3.192-3.186Z" transform="translate(-1152.788 -1179.278)" fill="#fff" />
      </g>
    </g>
  </g>,
  'Priority',
);

export default UserPriorityIcon;
