import React from 'react';
import { createSvgIcon } from '@mui/material';

const RepeatIcon = createSvgIcon(
  <path
    d="M1958.145,2285.312l2.045-2.046h-4.73a4.457,4.457,0,0,1-2.942-7.807l.836.836a3.279,3.279,0,0,0,2.106,5.795h4.688l-2-2,.836-.836,3.451,3.45-3.451,3.447Zm4.581-4.459a3.281,3.281,0,0,0-2.111-5.794h-4.688l2,2-.836.836-3.451-3.451,3.451-3.446.836.836-2.045,2.046h4.73a4.46,4.46,0,0,1,2.947,7.807Z"
    transform="translate(-1946 -2267)"
  />,
  'Repeat',
);

export default RepeatIcon;
