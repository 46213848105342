import Axios, {
  AxiosRequestConfig,
} from 'axios';
import i18next from 'i18next';
import { Snackbar } from '../../features/notistack';
import appConfig from '../config';
import storage from '../../utils/storage';
import type { UserResponse } from '../../features/auth/types';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers.Accept = 'application/json';
  config.withCredentials = true;
  return config;
}

const axios = Axios.create({
  baseURL: `${appConfig.api.baseUrl}/rest`,
});

// TODO: move in auth
async function onRefreshToken() {
  const refreshToken = storage.getRefreshToken();
  const formBody = [];
  formBody.push('grant_type=refresh_token');
  formBody.push(`refresh_token=${refreshToken}`);

  const response = await axios.post<UserResponse, UserResponse>(
    `${appConfig.api.baseUrl}/oauth/access_token`,
    formBody.join('&'),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return response;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = error?.response?.status || undefined;

    if (!status || status >= 500) {
      Snackbar.error(i18next.t('errors.serverError'));

      return Promise.reject();
    }

    if (status === 401) {
      if (!storage.getRefreshToken()) return Promise.reject();

      return onRefreshToken()
        .then(({ access_token, refresh_token }) => {
          storage.setTokens({
            accessToken: access_token,
            refreshToken: refresh_token,
          });
          return axios.request(error.config);
        })
        .catch(() => {
          storage.clearStorage();

          return Promise.reject();
        });
    }

    return Promise.reject(error);
  },
);

export default axios;
