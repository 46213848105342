import React from 'react';
import { createSvgIcon } from '@mui/material';

const SettingsIcon = createSvgIcon(
  <path
    d="M7.229,18.484a.818.818,0,0,1-.816-.709l-.305-2.125a7,7,0,0,1-1.2-.7l-2,.8a.87.87,0,0,1-.3.052.82.82,0,0,1-.724-.42L.111,12.331a.832.832,0,0,1,.2-1.061l1.7-1.33c-.025-.26-.039-.486-.039-.7a6.764,6.764,0,0,1,.04-.7L.314,7.216a.825.825,0,0,1-.2-1.065L1.883,3.089A.813.813,0,0,1,2.6,2.671a.828.828,0,0,1,.3.057l2.009.807a7.106,7.106,0,0,1,1.2-.7L6.415.7A.816.816,0,0,1,7.23,0h3.543a.819.819,0,0,1,.816.709l.3,2.127a6.863,6.863,0,0,1,1.2.7l2-.8a.872.872,0,0,1,.3-.053.823.823,0,0,1,.724.42l1.766,3.057a.832.832,0,0,1-.2,1.061l-1.7,1.33a6.59,6.59,0,0,1,.04.7,6.41,6.41,0,0,1-.04.7l1.7,1.328a.832.832,0,0,1,.208,1.055L16.119,15.4a.814.814,0,0,1-.716.417.831.831,0,0,1-.3-.056l-2.011-.807a7.151,7.151,0,0,1-1.2.7l-.305,2.133a.817.817,0,0,1-.815.7ZM5.823,13.677a5.4,5.4,0,0,0,.931.541l.786.355.122.853.214,1.488h2.25l.213-1.488.123-.852.784-.355a5.513,5.513,0,0,0,.936-.547l.7-.5.8.321,1.4.562L16.209,12.1l-1.187-.927-.688-.538.095-.868a4.914,4.914,0,0,0,.03-.529,5.073,5.073,0,0,0-.031-.533l-.091-.866.686-.536,1.186-.927L15.081,4.429l-1.4.562-.8.322-.7-.507a5.264,5.264,0,0,0-.926-.538l-.788-.354-.124-.856L10.126,1.57H7.877L7.663,3.059l-.122.852-.786.356a5.463,5.463,0,0,0-.935.546l-.7.5-.8-.32-1.4-.563L1.795,6.381l1.186.926.685.535-.09.864a5.284,5.284,0,0,0-.033.536c0,.155.011.328.032.545l.084.858-.679.531L1.793,12.1,2.92,14.055l1.4-.561.8-.323ZM7.716,9.242A1.286,1.286,0,1,1,9,10.528,1.286,1.286,0,0,1,7.716,9.242Z"
    transform="translate(3 2.75)"
  />,
  'Settings',
);

export default SettingsIcon;
