import React from 'react';
import { createSvgIcon } from '@mui/material';

const LocationBIcon = createSvgIcon(
  <g>
    <path
      d="M12 4.2351C10.3963 4.22125 8.85276 4.84494 7.70883 5.96898C6.5649 7.09303 5.91428 8.62536 5.90002 10.2291C5.90002 14.1381 12 19.7631 12 19.7631C12 19.7631 18.1 14.1381 18.1 10.2291C18.0931 9.43494 17.9298 8.65 17.6195 7.919C17.3092 7.18799 16.8579 6.52528 16.2915 5.96868C15.725 5.41208 15.0545 4.97249 14.3182 4.67504C13.5818 4.37759 12.7941 4.22811 12 4.2351V4.2351Z"
      fill="#29AAFF"
    />
    <path
      d="M13.8062 6.60156V7.2959H10.7212V13H9.87744V6.60156H13.8062ZM10.5234 9.20752H12.2681C12.7368 9.20752 13.1323 9.28662 13.4546 9.44482C13.7769 9.6001 14.0215 9.81836 14.1885 10.0996C14.3555 10.3809 14.439 10.7104 14.439 11.0884C14.439 11.3696 14.3921 11.6274 14.2983 11.8618C14.2046 12.0933 14.0654 12.2954 13.8809 12.4683C13.6963 12.6382 13.4692 12.77 13.1997 12.8638C12.9302 12.9546 12.6196 13 12.2681 13H9.87744V6.60156H10.7256V12.3101H12.2681C12.5815 12.3101 12.835 12.2529 13.0283 12.1387C13.2246 12.0215 13.3682 11.8691 13.459 11.6816C13.5498 11.4941 13.5952 11.2935 13.5952 11.0796C13.5952 10.8657 13.5498 10.6694 13.459 10.4907C13.3682 10.312 13.2246 10.1699 13.0283 10.0645C12.835 9.95605 12.5815 9.90186 12.2681 9.90186H10.5234V9.20752Z"
      fill="white"
    />
  </g>,
  'LocationB',
);

export default LocationBIcon;
