import React from 'react';
import { createSvgIcon } from '@mui/material';

const EyeInactiveIcon = createSvgIcon(
  <path
    d="M1952.062,2290.78l3.81-3.812a24.831,24.831,0,0,1-4.871-5.032s5.363-7.814,11.994-7.814a10.218,10.218,0,0,1,4.553,1.17l4.293-4.293,2.094,2.094-3.814,3.815h0l-2.463,2.464,0,0-7.217,7.217,0,0-2,2,0,0-4.282,4.283Zm5.619-8.844a5.254,5.254,0,0,0,.667,2.556l1.956-1.957a2.735,2.735,0,0,1,3.289-3.289l1.964-1.964a5.3,5.3,0,0,0-7.876,4.654Zm3.907,7.689,2.5-2.489a5.318,5.318,0,0,0,4.117-4.117v-.013l4.156-4.143a24,24,0,0,1,2.638,3.074s-5.375,7.814-12.006,7.814A7.659,7.659,0,0,1,1961.588,2289.626Z"
    transform="translate(-1951 -2269.937)"
  />,
  'EyeInactive',
);

export default EyeInactiveIcon;
