import React from 'react';
import { createSvgIcon } from '@mui/material';

const YoutubeIcon = createSvgIcon(
  <path
    d="M55.212-159.143a2.721,2.721,0,0,0-1.939-1.888c-1.721-.459-8.608-.459-8.608-.459s-6.886,0-8.607.441a2.775,2.775,0,0,0-1.939,1.906,27.561,27.561,0,0,0-.453,5.153,27.458,27.458,0,0,0,.453,5.153,2.719,2.719,0,0,0,1.939,1.888c1.739.459,8.608.459,8.608.459s6.886,0,8.607-.441a2.72,2.72,0,0,0,1.939-1.888,27.552,27.552,0,0,0,.453-5.152A26.147,26.147,0,0,0,55.212-159.143Zm-12.739,8.365V-157.2L48.2-153.99Z"
    transform="translate(-31.665 165.49)"
  />,
  'Youtube',
);

export default YoutubeIcon;
