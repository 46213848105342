import React from 'react';
import { createSvgIcon } from '@mui/material';

const ManipulatorIcon = createSvgIcon(
  <path
    d="M27.113,16.479A2.727,2.727,0,1,1,29.841,19.2,2.726,2.726,0,0,1,27.113,16.479Zm1.275,0a1.452,1.452,0,1,0,1.453-1.448A1.451,1.451,0,0,0,28.388,16.479Zm-25.171,0A2.727,2.727,0,1,1,5.945,19.2,2.726,2.726,0,0,1,3.217,16.479Zm1.277,0A1.451,1.451,0,1,0,5.945,15.03,1.45,1.45,0,0,0,4.494,16.479Zm28.765,0a3.419,3.419,0,0,0-6.838,0H14.376l-1.47-3.3H24.838V4.838a.926.926,0,0,1,.927-.925h5.324a.927.927,0,0,1,.847.549l2.35,5.267v3.428H33.094V13.2a.926.926,0,0,0,.927.925h.265v1.434a.926.926,0,0,1-.927.925ZM26.141,9.455H29.3a.929.929,0,0,1,.838.527l.621,1.3h1.913V9.455L30.925,5.419H26.141ZM9.364,16.479a3.414,3.414,0,0,0-2.552-3.3h5.34l1.468,3.3Zm-8.423,0a.926.926,0,0,1-.927-.925V13.179H5.077a3.415,3.415,0,0,0-2.551,3.3ZM24.148,12.49H0v-.682L.015,11.8H20.3l1.32-5.073h2.527V12.49ZM0,11.114v-.683l.006-.006H8.289l.306.688ZM1.2,6.408A.977.977,0,0,1,1.625,5.6h0a.555.555,0,0,1-.059-.253.577.577,0,0,1,.369-.536V3.238a.427.427,0,0,1-.295-.123.432.432,0,0,1-.124-.3.424.424,0,0,1,.424-.426h.617v-.4h.684v.4h.33l.28.851h-.61v.394H2.557V3.238H2.412v1.6a.576.576,0,0,1-.1,1.063h0l0,0h0a.568.568,0,0,1-.094.02h0a.491.491,0,0,0-.437.487.5.5,0,0,0,.489.488c.63.033.717-1.038.717-1.038a.89.89,0,0,1,.171.549.978.978,0,0,1-1.955,0Zm20.995-.364L14.051,3.377l.93-2.829,8.141,2.667a1.489,1.489,0,1,1-.93,2.829Zm-.078-1.593a.572.572,0,1,0,.543-.392A.569.569,0,0,0,22.113,4.451ZM4.051,2.209,12.773,0l1.15,1.349-.354,1.076L4.426,3.35Z"
  />,
  'Manipulator',
);

export default ManipulatorIcon;
