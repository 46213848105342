import React from 'react';
import { createSvgIcon } from '@mui/material';

const TwitterIcon = createSvgIcon(
  <path
    d="M49.647-285.635a7.183,7.183,0,0,1-2.008.557,3.5,3.5,0,0,0,1.533-1.952,6.9,6.9,0,0,1-2.21.855,3.472,3.472,0,0,0-2.546-1.117,3.508,3.508,0,0,0-3.483,3.533,3.671,3.671,0,0,0,.081.805,9.818,9.818,0,0,1-7.183-3.695,3.594,3.594,0,0,0-.477,1.786,3.55,3.55,0,0,0,1.548,2.936,3.4,3.4,0,0,1-1.574-.435v.039a3.538,3.538,0,0,0,2.792,3.471,3.438,3.438,0,0,1-.914.116,3.042,3.042,0,0,1-.66-.06,3.525,3.525,0,0,0,3.257,2.461,6.944,6.944,0,0,1-4.321,1.507,6.458,6.458,0,0,1-.834-.048,9.717,9.717,0,0,0,5.346,1.585,9.923,9.923,0,0,0,9.919-10.052c0-.156-.005-.307-.013-.457A7,7,0,0,0,49.647-285.635Z"
    transform="translate(-32.647 292.5)"
  />,
  'Twitter',
);

export default TwitterIcon;
