import React from 'react';
import { createSvgIcon } from '@mui/material';

const WithoutCallIcon = createSvgIcon(
  <g id="Without_call" transform="translate(0 0)">
    <path id="Path_425" data-name="Path 425" d="M1125.743,745.3a8.214,8.214,0,0,1-2.578-.412.728.728,0,0,0-.733.178l-1.588,1.592a10.851,10.851,0,0,1-2.266-1.549l7.787-7.787-.948-.949-7.788,7.788a10.935,10.935,0,0,1-1.545-2.259l1.588-1.592a.727.727,0,0,0,.178-.733,8.232,8.232,0,0,1-.412-2.581.72.72,0,0,0-.722-.722h-2.528a.72.72,0,0,0-.722.722,12.215,12.215,0,0,0,3.144,8.185l-3.043,3.043.948.949,3.043-3.044a12.216,12.216,0,0,0,8.185,3.145.72.72,0,0,0,.722-.722v-2.528A.721.721,0,0,0,1125.743,745.3Z" transform="translate(-1107.966 -730.775)" fill="#fff" />
  </g>,
  'Without_call',
);

export default WithoutCallIcon;
