import React from 'react';
import { createSvgIcon } from '@mui/material';

const LocationCIcon = createSvgIcon(
  <g>
    <path
      d="M12 4.2351C10.3963 4.22125 8.85276 4.84494 7.70883 5.96898C6.5649 7.09303 5.91428 8.62536 5.90002 10.2291C5.90002 14.1381 12 19.7631 12 19.7631C12 19.7631 18.1 14.1381 18.1 10.2291C18.0931 9.43494 17.9298 8.65 17.6195 7.919C17.3092 7.18799 16.8579 6.52528 16.2915 5.96868C15.725 5.41208 15.0545 4.97249 14.3182 4.67504C13.5818 4.37759 12.7941 4.22811 12 4.2351V4.2351Z"
      fill="#29AAFF"
    />
    <path
      d="M12.2197 10.0073H10.5981L10.5894 9.32617H12.0615C12.3047 9.32617 12.5171 9.28516 12.6987 9.20312C12.8804 9.12109 13.021 9.00391 13.1206 8.85156C13.2231 8.69629 13.2744 8.51172 13.2744 8.29785C13.2744 8.06348 13.229 7.87305 13.1382 7.72656C13.0503 7.57715 12.9141 7.46875 12.7295 7.40137C12.5479 7.33105 12.3164 7.2959 12.0352 7.2959H10.7871V13H9.93896V6.60156H12.0352C12.3633 6.60156 12.6562 6.63525 12.9141 6.70264C13.1719 6.76709 13.3901 6.86963 13.5688 7.01025C13.7505 7.14795 13.8882 7.32373 13.9819 7.5376C14.0757 7.75146 14.1226 8.00781 14.1226 8.30664C14.1226 8.57031 14.0552 8.80908 13.9204 9.02295C13.7856 9.23389 13.5981 9.40674 13.3579 9.5415C13.1206 9.67627 12.8423 9.7627 12.5229 9.80078L12.2197 10.0073ZM12.1802 13H10.2642L10.7432 12.3101H12.1802C12.4497 12.3101 12.6782 12.2632 12.8657 12.1694C13.0562 12.0757 13.2012 11.9438 13.3008 11.7739C13.4004 11.6011 13.4502 11.3975 13.4502 11.1631C13.4502 10.9258 13.4077 10.7207 13.3228 10.5479C13.2378 10.375 13.1045 10.2417 12.9229 10.1479C12.7412 10.0542 12.5068 10.0073 12.2197 10.0073H11.0112L11.02 9.32617H12.6724L12.8525 9.57227C13.1602 9.59863 13.4209 9.68652 13.6348 9.83594C13.8486 9.98242 14.0112 10.1699 14.1226 10.3984C14.2368 10.627 14.2939 10.8789 14.2939 11.1543C14.2939 11.5527 14.2061 11.8896 14.0303 12.165C13.8574 12.4375 13.6128 12.6455 13.2964 12.7891C12.98 12.9297 12.6079 13 12.1802 13Z"
      fill="white"
    />
  </g>,
  'LocationC',
);

export default LocationCIcon;
