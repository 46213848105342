import React from 'react';
import { createSvgIcon } from '@mui/material';

const WalletIcon = createSvgIcon(
  <path
    id="cash"
    d="M1562.791,2411.65a3.779,3.779,0,0,1-3.775-3.775l-.016-10.1a3.778,3.778,0,0,1,3.775-3.774h11.93a3.778,3.778,0,0,1,3.775,3.774l.016,10.1a3.778,3.778,0,0,1-3.774,3.775Zm-2.24-13.876.016,10.1a2.225,2.225,0,0,0,2.224,2.224h11.931a2.225,2.225,0,0,0,2.223-2.224l-.016-1.672-6.647-.044a3.333,3.333,0,0,1,0-6.667h6.6l.045-1.718a2.226,2.226,0,0,0-2.224-2.222h-11.93A2.226,2.226,0,0,0,1560.551,2397.774Zm7.95,5.052a1.783,1.783,0,0,0,1.781,1.78h6.6l.045-3.516-6.647-.045A1.784,1.784,0,0,0,1568.5,2402.825Zm1.324,0a.828.828,0,1,1,.828.83A.829.829,0,0,1,1569.824,2402.824Z"
    transform="translate(-1557 -2391)"
  />,
  'Wallet',
);

export default WalletIcon;
