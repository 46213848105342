import React from 'react';
import { createSvgIcon } from '@mui/material';

const VKIcon = createSvgIcon(
  <path
    d="M35.368-190.074c.284.555.027,1.163-.464,1.2H31.846a2.349,2.349,0,0,1-1.948-.8c-.425-.435-.816-.9-1.225-1.348a2.81,2.81,0,0,0-.55-.493.634.634,0,0,0-1.017.25,3.271,3.271,0,0,0-.324,1.441c-.034.728-.25.919-.974.953a7.768,7.768,0,0,1-4.377-.95,9.7,9.7,0,0,1-2.945-2.785,37.714,37.714,0,0,1-3.876-6.978c-.244-.552-.067-.845.535-.857,1-.018,2-.016,2.994,0a.879.879,0,0,1,.831.629,18.958,18.958,0,0,0,2.031,3.8,3.221,3.221,0,0,0,.767.852.464.464,0,0,0,.791-.234,2.674,2.674,0,0,0,.177-.8A12,12,0,0,0,22.685-199a1.157,1.157,0,0,0-.983-1.064c-.29-.056-.247-.166-.107-.333a1.1,1.1,0,0,1,.941-.472H25.99c.543.108.666.355.739.907l0,3.877c-.006.216.107.852.489.99.305.1.507-.145.69-.339a12.13,12.13,0,0,0,1.949-3.02c.232-.481.434-.975.629-1.471a.745.745,0,0,1,.782-.54l3.327,0a1.6,1.6,0,0,1,.3.018c.559.1.715.339.541.891a8.4,8.4,0,0,1-1.323,2.317c-.556.777-1.148,1.527-1.7,2.3-.507.712-.468,1.073.162,1.691a28.67,28.67,0,0,1,2.056,2.091A5.709,5.709,0,0,1,35.368-190.074Z"
    transform="translate(-14.9 206.4)"
  />,
  'VK',
);

export default VKIcon;
